import ExploreNearbyComponent from "../components/ExploreComponen";
import { useNav } from "../hooks/useNav";
import info from "../assests/info.png";
import info2 from "../assests/info-2.png";
import info3 from "../assests/info-3.png";


const Destinations = () => {
  const destinationRef = useNav("destinationsSection");

  return (
    <section
      ref={destinationRef}
      id="destinationsSection"
      className="secondary-section"
    >
      <h3 className="text-24">See It, Tap It, and You're There!</h3>
      <ExploreNearbyComponent
        title="Explore Nearby"
        description="Open GemsAround to uncover must-see spots and hidden gems tailored to your preferences and receive traveler tips. Simply swipe to explore—no planning needed, just pure discovery! Find unique local treasures and global travel inspiration all in one place."
        subtitle="Instant Adventure Awaits"
        image={info}
        order={2}
      />
      <ExploreNearbyComponent
        title="Plan in Moments"
        description="Whether you have a few hours or two weeks, simply set your time and interests, and our web service will craft your ideal itinerary. Effortlessly add, remove, or swap spots anytime, with AI-powered adjustments to keep your trip perfectly on track."
        subtitle="Easy AI Itinerary Builder"
        image={info2}
        order={1}
      />
      <ExploreNearbyComponent
        title="Share with Tripmates"
        description="
        Get real-time updates on hidden gems and main attractions from fellow travelers. Share photos, indicate how busy a spot is, and ensure every stop is as memorable as promised, with each discovery meeting your expectations."
        subtitle="Community Powered"
        image={info3}
        order={2}
      />
    </section>
  );
};

export default Destinations;
