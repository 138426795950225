import React from "react";
import person from "../assests/person-1.png";
import person2 from "../assests/person-2.png";
import person3 from "../assests/person-3.png";
import person4 from "../assests/person-4.png";
const ImageSet = () => {
  return (
    <div className="image-set-container">
      <div className="image-set">
        <img src={person} alt="Imagine 1" className="circle-image" />
        <img src={person2} alt="Imagine 1" className="circle-image" />
        <img src={person3} alt="Imagine 1" className="circle-image" />
        <img src={person4} alt="Imagine 1" className="circle-image" />
      </div>
      <div className="costumer-block">
        <span className="text-16">500+</span>
        <span className="text-14-grey">Users tested the Beta version</span>
      </div>
    </div>
  );
};

export default ImageSet;
