import { useNav } from "../hooks/useNav";
import IconsBlock from "../components/IconsBlock";
import MediaComponent from "../components/MediaComponent";

const Home = () => {
  const homeRef = useNav("aladainSection");

  return (
    <section ref={homeRef} id="aladainSection" className="solution-container-1">
      <div className="section-1">
        <span className="text-14-green">AI Travel Assistant</span>
        <h2 className="text-32">
          Hey, travelers! Tired of researching and planning?
        </h2>
        <p className="text-14-grey">
          Gems Around helps you find Instagrammable spots and hidden gems, plan
          efficient routes, and get real-time travel updates. Skip the research
          and start exploring!
        </p>
        <a href="https://app.gemsaround.com/" className="action-btn" target="_blank"   rel="noopener noreferrer">
          Try GemsAround
        </a>
      </div>
      <MediaComponent />
      <div className="solution-container-text" style={{ paddingTop: "0" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="text-14-green">Discover Effortlessly</span>
          <h3 className="text-24">All-in-One Travel Solution</h3>
          <p className="text-14-grey">
            Forget juggling multiple apps! Our platform combines main
            attractions, hidden gems, and local favorites—all in one convenient
            spot. Simplify your travel planning and explore more with ease.
          </p>
        </div>
        <IconsBlock />
      </div>
    </section>
  );
};

export default Home;
