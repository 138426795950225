import React from "react";

const ExploreNearbyComponent = ({
  image,
  title,
  description,
  subtitle,
  order,
}) => {
  return (
    <div className={`solution-container order-${order}`} style={{ margin: "32px 0 0 0" }}>
      <div className="col" style={{ order: order }}>
        <span className="text-14-green">{title}</span>
        <h3 className="text-20">{subtitle}</h3>
        <p className="text-14-grey start">{description}</p>
      </div>
      <div className="img-box">
        <img src={image} className="image-1" alt="place"/>
      </div>
    </div>
  );
};

export default ExploreNearbyComponent;
