import Header from "./components/Header";
import Destinations from "./sections/Destinations";
import Home from "./sections/Home";
import Hotels from "./sections/Hotels";
import NavProvider from './context/NavContext'
import FooterBlock from "./components/Footer";

function App() {
  return (
    <div>
      <NavProvider>
        <Header />
        <Home />
        <Destinations />
        <Hotels />
         <FooterBlock/>
      </NavProvider>
    </div>
  );
}

export default App;
