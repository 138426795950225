import { useState } from "react";
import { useNav } from "../hooks/useNav";
import ImageSet from "../components/ImageSet";

const Hotels = () => {
  const hotelRef = useNav("hotelsSection");

  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const submitEmail = async () => {
    if (!validateEmail(email)) {
      setStatus("invalid");
      return;
    }

    setStatus("loading");

    try {
      const response = await fetch(
        "https://api.gemsaround.com/api/subscribe",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.ok) {
        setStatus("success");
      } else {
        setStatus("error");
      }
    } catch (error) {
      console.error("Eroare:", error);
      setStatus("error");
    }
  };

  return (
    <section ref={hotelRef} id="hotelsSection" className="join-container">
      <div className="solution-container-text">
        <span className="text-14-green">Secure Your Spot!</span>
        <h3 className="text-24">Join Waitlist Today</h3>
        <p className="text-14-grey">
          Embark on a new era of travel with our AI-driven platform. Join our
          waitlist now and be among the first to explore the world with
          cutting-edge technology at your fingertips.
        </p>
      </div>
      <div className="email-form-c">
        <div className="email-form">
          <input
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setStatus(""); // Resetăm statusul atunci când utilizatorul editează emailul
            }}
            placeholder="Get early access – enter your email"
            className={`email-input ${status}`}
            required
          ></input>
          <button
            type="button"
            onClick={submitEmail}
            className={`action-btn ${status}`}
            disabled={status === "loading"}
            style={{ border: "0" }}
          >
            {status === "loading" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM4.91864 12C4.91864 15.9109 8.08907 19.0814 12 19.0814C15.9109 19.0814 19.0814 15.9109 19.0814 12C19.0814 8.08907 15.9109 4.91864 12 4.91864C8.08907 4.91864 4.91864 8.08907 4.91864 12Z"
                  fill="url(#paint0_angular_2388_4829)"
                />
                <defs>
                  <radialGradient
                    id="paint0_angular_2388_4829"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(9.08333 10.3333) scale(21.25)"
                  >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" />
                  </radialGradient>
                </defs>
              </svg>
            ) : (
              "Send"
            )}
          </button>
        </div>
        {status === "invalid" && (
          <span className="error-text">
            Please enter a valid email address.
          </span>
        )}
        {status === "success" && (
          <span className="success-text">
            Your email was sent successfully.
          </span>
        )}
      </div>

      <ImageSet />
    </section>
  );
};

export default Hotels;
